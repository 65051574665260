import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import OnboardingMiddleware from "../hocs/OnboardingMiddleware";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

export const Home = () => {
  return (
    <div>
      <Navbar />
      <OnboardingMiddleware>
      <Sidebar />
        {/* Contenido específico del Home */}
        Home view here
      </OnboardingMiddleware>
    </div>
  );
};

export default withAuthenticationRequired(Home, {
  onRedirecting: () => <p>Loading</p>,
});

import { useAuth0 } from '@auth0/auth0-react';
import { jwtDecode } from "jwt-decode";

let cachedToken = null;
let tokenExpiry = null;

export const useTokenManagement = () => {
    const { getAccessTokenSilently } = useAuth0();

    const getToken = async () => {
        if (cachedToken && new Date() < tokenExpiry) {
            return cachedToken;
        }

        const token = await getAccessTokenSilently();
        cachedToken = token;
        const decodedToken = jwtDecode(token); // Decodifica el token para obtener la fecha de expiración
        tokenExpiry = new Date(decodedToken.exp * 1000);

        return token;
    };

    return {
        getToken,
    };
};

import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Route, Switch } from "react-router-dom";
import Navbar from "../components/Navbar";
import OrganizationManagementSidebar from "../components/OrganizationManagementSidebar";
import MemberComponent from "./organization-management/MemberComponent";
import Home from "./organization-management/Home";

export const OrganizationManagement = ({ match }) => {
  return (
    <div>
      <Navbar />
      <div style={{ display: 'flex' }}>
        <OrganizationManagementSidebar />
        <div style={{ padding: 50, flexGrow: 1 }}>
          <Switch>
            <Route path={`/manage-organization/members`} exact component={MemberComponent} />
            <Route exact path={match.path} component={Home} />
          </Switch>
        </div>
      </div>
    </div >
  );
};

export default withAuthenticationRequired(OrganizationManagement, {
  onRedirecting: () => <p>Loading</p>,
});

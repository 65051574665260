import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, PageHeader, StandaloneMultiEmailInput, Table } from '@labqube/components';
import useOrganizations from '../../hooks/useOrganizations';
import useOrganizationMembers from '../../hooks/useOrganizationMembers';

const MemberComponent = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [invitedEmails, setInvitedEmails] = useState([]);

    const { isLoading: isLoadingOrgs, data: organizations } = useOrganizations();
    const { isLoading: isLoadingMembers, members, addMember } = useOrganizationMembers(organizations?.[0]?._id);

    const headers = ['Name', 'Email', 'Role', 'Status', 'Actions'];

    const items = Array.isArray(members) ? members.map(member => ({
        id: member._id,
        content: {
            name: member.name,
            email: member.email,
            role: member.role,
            status: member.invite_sent ? 'Invite Sent' : 'Active',
            actions: () => (
                <div>
                    <i
                        className="fa-solid fa-trash clickable"
                    ></i>
                </div>
            ),
        },
    })) : [];

    if (isLoadingOrgs || isLoadingMembers || !items) {
        return <p>Loading...</p>;
    }

    const openModal = () => {
        setInvitedEmails([]);
        setIsModalVisible(true);
    };

    const closeModal = () => setIsModalVisible(false);

    const handleEmailsChange = (emails) => {
        setInvitedEmails(emails);
    };

    const sendInvitations = () => {
        invitedEmails.forEach(email => {
            addMember({ 
                organization_id: organizations?.[0]?._id,
                email,
                name: email,
                role: 'member',
            });
        });

        closeModal();
        setInvitedEmails([]);
    };

    return (
        <div>
            <PageHeader
                style={{ wordBreak: 'break-word' }}
                title={'Manage Organization Members'}
                breadcrumbs={[
                    {
                        href: <Link to={'/manage-organization'}>Organization</Link>
                    },
                    {
                        href: '',
                        text: `Members`
                    }
                ]}
                action={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            type="primary"
                            onClick={openModal}
                            style={{ height: 37 }}
                        >
                            Invite team members
                        </Button>
                    </div>
                }
            />
            <Table
                headers={headers}
                columnsWidth={['25%', '25%', '25%']}
                items={items}
                pagination={true}
            />

            <Modal
                visible={isModalVisible}
                title="Invite Team Members"
                footer={
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={closeModal} style={{ height: 37, marginRight: 10 }}>Cancel</Button>
                        <Button onClick={sendInvitations} style={{ height: 37 }} type="primary">Send Invitations</Button>
                    </div>
                }
            >
                <div style={{ width: '80%', margin: 'auto' }}>
                    <StandaloneMultiEmailInput
                        placeholder="Enter email and press 'Enter'"
                        onEmailsChange={handleEmailsChange}
                    />
                </div>
            </Modal>
        </div>
    );
}

export default MemberComponent;

import React, { useEffect, useState } from 'react';
import Navbar from "../components/Navbar";
import { StandaloneButton, StandaloneInput, StandaloneMultiEmailInput, StandaloneProgressBar } from '@labqube/components'; // Asegúrate de usar los nombres de importación correctos

import { useHistory } from 'react-router-dom';

import './onboarding.css';
import useOrganizations from '../hooks/useOrganizations';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const Onboarding = () => {
    const [stage, setStage] = useState(1);
    const [organizationName, setOrganizationName] = useState('');
    const [stageClass, setStageClass] = useState('onboarding-stage');
    const [progress, setProgress] = useState(0);
    const [emails, setEmails] = useState([]);
    const [progressCompleted, setProgressCompleted] = useState(false);
    const { 
        createOrganization,
        isCreatingOrganization, 
        isCreateOrganizationSuccessful
    } = useOrganizations();

    const history = useHistory();

    useEffect(() => {
        if (stage === 3) {
            createOrganization({ name: organizationName });
    
            const interval = setInterval(() => {
                setProgress((oldProgress) => {
                    const newProgress = Math.min(oldProgress + 10, 100);
                    if (newProgress >= 100) {
                        clearInterval(interval);
                        setProgressCompleted(true);
                    }
                    return newProgress;
                });
            }, 1000);
            return () => clearInterval(interval);
        }
        // eslint-disable-next-line
    }, [stage]);

    useEffect(() => {
        if (isCreateOrganizationSuccessful && progressCompleted && stage === 3) {
            changeStage(4);
        }
    // eslint-disable-next-line
    }, [isCreateOrganizationSuccessful, isCreatingOrganization, progressCompleted, stage]);

    useEffect(() => {
        setStageClass('onboarding-stage onboarding-stage-enter');
    }, []);

    const handleOrganizationNameChange = (e) => {
        setOrganizationName(e.target.value);
    };

    const handleOrganizationNameKeyDown = (e) => {
        if (e.key === 'Enter' && organizationName) {
            changeStage(3);
        }
    };

    const changeStage = (newStage) => {
        if (stage === 2 && newStage === 3) {
            if (!organizationName) {
                return
            }
        }

        setStageClass('onboarding-stage onboarding-stage-exit');

        setTimeout(() => {
            setStageClass('onboarding-stage');
            setStage(newStage);
        }, 300);

        setTimeout(() => {
            setStageClass('onboarding-stage onboarding-stage-enter');
        }, 600);
    };

    const handleEmailsChange = (newEmails) => {
        setEmails(newEmails);
    };

    const sendInvitations = () => {
        console.log('Invitations sent to:', emails);
        // Aquí iría la lógica para enviar las invitaciones, como una solicitud API.
        // Después de la petición HTTP, redireccionamos al usuario a /home
        history.push('/#/home');
    };

    const skipInvitations = () => {
        // Redireccionar al usuario a /home directamente
        history.push('/#/home');
    };

    const renderStageOne = () => (
        <div className={stageClass}>
            <div className="onboarding-content">
                <h1>Welcome to Team Plays!</h1>
                <p>First of all, let's setup your account</p>
                <div className="actions">
                    <StandaloneButton type="primary" onClick={() => changeStage(2)}>Create an organization</StandaloneButton>
                    <span>or</span>
                    <StandaloneButton type="secondary">Join an organization</StandaloneButton>
                </div>
            </div>
            <div className="onboarding-image">
                <img src="/images/onboarding/welcome accent image.png" alt="Setup Account" />
            </div>
        </div>
    );

    const renderStageTwo = () => (
        <div className={stageClass}>
            <div className="onboarding-content">
                <h1>How should we call your organization?</h1>
                <StandaloneInput
                    autoFocus
                    style={{ width: '90%' }}
                    placeholder="Type a name for your organization"
                    value={organizationName}
                    onChange={handleOrganizationNameChange}
                    onKeyDown={handleOrganizationNameKeyDown}
                />
                <div className="actions vertical">
                    <StandaloneButton
                        type="primary"
                        onClick={() => changeStage(3)}
                        disabled={!organizationName}
                    >
                        Let's go!
                    </StandaloneButton>
                    <StandaloneButton type="action" onClick={() => changeStage(1)}>Changed my mind</StandaloneButton>
                </div>
            </div>
            <div className="onboarding-image">
                <img src="/images/onboarding/create-organization-image.png" alt="Create Organization" />
            </div>
        </div>
    );

    const renderCreatingOrganization = () => (
        <div className={stageClass}>
            <div className="onboarding-content">
                <h1>Our bots are creating your organization... please wait</h1>
                <StandaloneProgressBar progress={progress} />
            </div>
            <div className="onboarding-image">
                <img src="/images/onboarding/creating-organization-image.png" alt="Creating Organization" />
            </div>
        </div>
    );

    const renderInviteTeammates = () => (
        <div className={stageClass}>
            <div className="onboarding-content">
                <h1>Would you like to invite teammates?</h1>
                <StandaloneMultiEmailInput
                    style={{ width: '100%' }}
                    placeholder="Add email and hit enter"
                    onEmailsChange={handleEmailsChange}
                />
                <div className="actions vertical">
                    <StandaloneButton
                        type="primary"
                        onClick={sendInvitations}
                        disabled={!emails.length}
                    >
                        Email them!
                    </StandaloneButton>
                    <StandaloneButton type="action" onClick={skipInvitations}>Skip for now</StandaloneButton>
                </div>
            </div>
            <div className="onboarding-image">
                <img src="/images/onboarding/invite-teammates-image.png" alt="Invite Teammates" />
            </div>
        </div>
    );

    return (
        <div>
            <Navbar />
            {stage === 1 && renderStageOne()}
            {stage === 2 && renderStageTwo()}
            {stage === 3 && renderCreatingOrganization()}
            {stage === 4 && renderInviteTeammates()}
        </div>
    );
};

export default withAuthenticationRequired(Onboarding, {
    onRedirecting: () => <p>Loading</p>,
  });

import React from 'react';
import { useLocation } from 'react-router-dom';
import './OrganizationManagementSidebar.css';

const OrganizationManagementSidebar = () => {
    const location = useLocation();
    const currentPath = location.pathname + location.hash;

    const isActive = (path) => {
        return currentPath.replace('/', '') === path;
    };

    return (
        <nav className="sidebar">
            <ul className="sidebar-nav">
                <li className="nav-item">
                    <a href="/#/manage-organization/members" className={`nav-link ${isActive('manage-organization/members') ? 'active' : ''}`}>
                        Members
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default OrganizationManagementSidebar;

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { StandaloneNavbar } from '@labqube/components';
import history from "../utils/history";

const Navbar = () => {
  const { user, logout } = useAuth0();

  async function handleLogout() {
    await logout({ logoutParams: { returnTo: window.location.origin }});
    history.push('/#/');
  }

  return (
    <StandaloneNavbar username={user.name} avatar={user.picture} handleLogout={handleLogout} />
  );
};

export default Navbar;

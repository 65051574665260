import React from 'react';
import { Message, PageHeader } from '@labqube/components';

const Home = () => {
    return (
        <div>
            <PageHeader
                style={{ wordBreak: 'break-word' }}
                title={'Organization Settings'}
                breadcrumbs={[
                    {
                        href: '',
                        text: `Organization`
                    }
                ]}
                action={<></>}
            />
            <Message
                title={
                    <>
                        <p>Welcome to Organization Management.</p>
                        <p>Select an option from the menu to get started.</p>
                    </>
                }
            />
        </div>
    );
}

export default Home;

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAxiosConfig } from '../utils/axios';

const useOrganizationMembers = (organizationId) => {
    const axios = useAxiosConfig();
    const queryClient = useQueryClient();

    const { isLoading, data, error } = useQuery({
        queryKey: ['organization-members', organizationId],
        queryFn: () => axios.get(`/api/organizations/organizations/${organizationId}/members`).then(response => response.data),
        enabled: !!organizationId,
    });

    const addMemberMutation = useMutation(
        (newMember) => axios.post(`/api/organizations/members`, newMember),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['organization-members', organizationId]);
            },
        }
    );

    return {
        isLoading,
        members: data,
        error,
        addMember: addMemberMutation.mutate,
        isAddMemberSuccessful: addMemberMutation.isSuccess,
        isAddingMember: addMemberMutation.isLoading,
        hasAddMemberError: addMemberMutation.error
    };
}

export default useOrganizationMembers;

import React, { useEffect } from "react";
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

//views
import Home from "./views/Home";
import Onboarding from "./views/Onboarding";

// styles
import "./App.css";
import '@labqube/components/dist/index.css';
import OrganizationManagement from "./views/OrganizationManagement";

const App = () => {
  const { isAuthenticated, isLoading, error } = useAuth0();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      history.push('/#/home');
    }
  }, [isLoading, isAuthenticated]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <p>Loading</p>;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <Switch>
          <Route path="/home" exact component={Home} />
          <Route path="/onboarding" exact component={Onboarding} />
          <Route path="/manage-organization" exact component={OrganizationManagement} />
          <Route path="/manage-organization/*" component={OrganizationManagement} />
          <Redirect to="/home" />
        </Switch>
      </div>
    </Router>
  );
};

export default App;

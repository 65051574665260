import React from 'react';
import { useLocation } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = () => {
    const location = useLocation();
    const currentPath = location.pathname + location.hash;

    const isActive = (path) => {
        return currentPath.replace('/', '') === path;
    };

    return (
        <nav className="sidebar">
            <ul className="sidebar-nav">
                <li className="nav-item">
                    <a href="/#/home" className={`nav-link ${isActive('home') ? 'active' : ''}`}>
                        Home
                    </a>
                </li>
                <li className="nav-title">Collaboration</li>
                <li className="nav-item">
                    <a href="/#/retrospectives" className={`nav-link ${isActive('retrospectives') ? 'active' : ''}`}>
                        <img src='/images/sidebar/retrospectives.png' alt="Retrospectives" />
                        <span>Retrospectives</span>
                    </a>
                </li>
                <li className="nav-title">Monitoring</li>
                <li className="nav-item">
                    <a href="/#/health-monitor" className={`nav-link ${isActive('health-monitor') ? 'active' : ''}`}>
                        <img src='/images/sidebar/health-monitor.png' alt="Health Monitor" />
                        <span>Health Monitor</span>
                    </a>
                </li>
                <li className="nav-title">Planning</li>
                <li className="nav-item">
                    <a href="/#/planning-poker" className={`nav-link ${isActive('/#/planning-poker') ? 'active' : ''}`}>
                        <img src='/images/sidebar/planning-poker.png' alt="Planning Poker" />
                        <span>Planning Poker</span>
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default Sidebar;

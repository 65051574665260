import React from 'react';
import { Redirect } from 'react-router-dom';
import useOrganizations from '../hooks/useOrganizations';

const OnboardingMiddleware = ({ children }) => {
  const { isLoading, data: organizations } = useOrganizations();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (organizations && organizations.length === 0) {
    return <Redirect to="/onboarding" />;
  }

  return <>{children}</>;
};

export default OnboardingMiddleware;

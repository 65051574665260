import { useQuery, useMutation, useQueryClient, defaultContext } from '@tanstack/react-query';
import { useAxiosConfig } from '../utils/axios';

const useOrganizations = () => {
    const axios = useAxiosConfig();
    const queryClient = useQueryClient();

    const { isLoading, data } = useQuery({
        context: defaultContext,
        queryKey: ['organizations'],
        queryFn: () => axios.get('/api/organizations/organizations').then(response => response.data)
    });

    const createOrganizationMutation = useMutation(
        (newOrganization) => axios.post('/api/organizations/organizations', newOrganization),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['organizations']);
            },
            onError: (error) => {
                console.error('Error creating organization:', error);
            }
        }
    );

    return {
        isLoading,
        data,
        createOrganization: createOrganizationMutation.mutate,
        isCreateOrganizationSuccessful: createOrganizationMutation.isSuccess,
        isCreatingOrganization: createOrganizationMutation.isLoading,
        hasCreateOrganizationError: createOrganizationMutation.error
    };
}

export default useOrganizations;

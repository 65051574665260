import axios from 'axios';
import { useTokenManagement } from './token-management';

export const useAxiosConfig = () => {
    const { getToken } = useTokenManagement();

    const axiosInstance = axios.create({
        baseURL: `${process.env.REACT_APP_API_BASE_URL}`
    });

    axiosInstance.interceptors.request.use(async config => {
        try {
            const token = await getToken();
            config.headers.Authorization = `${token}`;
            return config;
        } catch (error) {
            return Promise.reject(error);
        }
    });

    return axiosInstance;
};
